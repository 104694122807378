.article {
  padding: 0 .5em;
  display: flex;
  flex-direction: column;

  &__gallery-cntr {
    height: 100%;
    width: 100%;
    margin: 0 auto;

    .gallery {
      height: 280px;
      width: 100%;
      object-fit: cover;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;

      .img-cntr {
        height: calc(50% - .2em);
        object-fit: cover;
        display: flex;

        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
        }
        &:first-child, &:last-child {
          width: calc(40% - .1em);
        }
        &:nth-child(2), &:nth-child(3) {
          width: calc(60% - .1em);
        }
      }
    }
  }
  &__txt {
    padding: .5rem 0;
    display: flex;
    flex-direction: column;

    .article-title {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      h3 {
        margin-top: 0;
        font-size: $fs_m;
        text-transform: uppercase;
      }
      .article-links {
        height: 100%;
        width: auto;

        a {
          margin-left: .5em;
          text-decoration: none;
          color: $clr_l_grey;

          svg {
            height: 20px;
            width: auto;
          }
        }
      }
    }
    .article-build {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 1.25em;

      .text {
        margin: 0;
        font-family: $ff_mono;
        font-size: $fs_s;
        font-weight: 600;
        color: $clr_acc_yel;
        word-spacing: 1px;
        letter-spacing: 0.25px;
        text-transform: uppercase;
      }
      
    }
    .article-desc {
      display: flex;
      flex-direction: column;
    }
  }
}


@media (min-width: 600px) {
  .article {
    height: auto;
    padding: 0 0;
    flex-direction: row;

    &__gallery-cntr {
      min-width: 50%;

      .gallery {
        height: 320px;
      }
    }
    &__txt {
      margin: auto 0;
      padding: 0 0 0 .5rem;
    }
  }
}


@media (min-width: 992px) {
  .article {
    justify-content: space-between;

    &__txt {
      .article-header {
        margin-bottom: .625em;

        .article-title {
          h3 {
            margin: 0 0 0.625em 0;
            font-size: $fs_l;
          }
        }
      }
    }
  }
}




// -----------------------------------------




.article-mini {
  margin: .5em;
  padding: 1em .5em;
  display: flex;
  flex-direction: column;
  border: solid $clr_m_grey 1px;
  // background-color: rgba(110, 110, 110, 0.25);
  border-radius: .25em;

  &__txt {
    padding: 0;
    display: flex;
    flex-direction: column;

    .article-mini-header {
      margin: 0 0 .5em 0;
      display: flex;
      flex-direction: column;
    }

    .article-mini-build {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      column-gap: 1.25em;

      &__text {
        margin: 0;
        font-family: $ff_mono;
        font-size: $fs_m;
        font-weight: 600;
        color: $clr_acc_yel;
        word-spacing: .0625em;
        letter-spacing: .015625em;
        text-transform: uppercase;
      }
    }
  }
  .article-mini-desc {
    display: flex;
    flex-direction: column;
    gap: .5em;

    span {
      margin: 0;
    }
  }
}
  

@media (min-width: 600px) {
  .article-mini {
    width: calc(50% - .5em);
    margin: 0;
    padding: 0;

    &__txt {
      margin: 2em 1.5em;
    }
  }
}

// @media (min-width: 992px) {
//   .article-mini {
//     width: calc(33.33% - .5em);
//   }
// }
