
/* ---------- RESETS ---------- */
* {
    margin: 0;
    padding: 0;
}

html, body {
    height: 100vh;
    width: 100%;
}

body {
    font-family: $ff_sans;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
    font-size: 16px;
    color: $clr_white;
    background-color: $clr_black;
}

#root {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
}
