.hero-cntr {
  height: 100vh;
  width: 100%;
  margin: 0 0 1.625rem 0 ;
  display: flex;
  flex-direction: column;

  &__txt {
    height: auto;
    width: 85%;
    margin: auto 0;
    margin-left: 10px;

    h1 {
      margin: .625rem 0;
      font-size: $fs_header;
    }

    .text {
      margin-bottom: 0.625rem;
      font-family: $ff_mono;
      font-size: $fs_s;
      font-weight: 400;
      text-transform: none;
      word-spacing: 1px;
      letter-spacing: 0.25px;
    }
  }
}

@media (min-width: 600px) {
  .hero-cntr {
    &__txt {
      // height: auto;
      width: 70%;
      // margin-left: 0;
    }
  }
}

@media (min-width: 992px) {
  .hero-cntr {
    &__txt {
      width: 60%;
    }
  }
}
