.cta-cntr {

  &.center-spaced {
    width: 100%;
    margin: 1em auto;
    display: flex;
    justify-content: center;
  }

  .cta {
    margin: .5em auto;
    padding: 0.5em 1.5em;
    border: solid $clr_acc_yel 2px;
    border-radius: 4px;
    background-color: transparent;
    color: $clr_acc_yel;
    font-size: $fs_m;
    text-decoration: none;
    cursor: pointer;
    transition: .2s;

    &:hover {
      box-shadow: 0px 0px 4px 1px $clr_acc_yel;
    }
  }      
}
