#nav {
  width: 100%;
  margin: 0 auto;
  top: 0;
  position: fixed;
  display: flex;
  flex-direction: column-reverse;
  z-index: 100;
}

.nav-cntr {
  height: 2em;
  width: 100%;
  padding: .5em 0;
  top: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: $black_transparent;
  backdrop-filter: blur(2px);
  box-shadow: 0 4px 10px -10px $clr_black;

  .nav-inner-cntr {
    width: 100%;
    margin: 0 auto;
    padding: 0 .25em;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    z-index: 999;

    a.navlink {
      font-size: $fs_s;
      text-decoration: none;
      text-transform: uppercase;
      color: $txt_light;
      transition: $hover_ease_in_02;

      &:hover {
        color: $clr_acc_yel;
      }
    }

    &__logo {
      margin: auto .25em;
    }

    &__links{
      display: none;
    }

    .burger-menu {
      height: auto;
      width: max-content;
      position: absolute;
      margin: .5em 0;
      padding: .2em .25em;
      right: 0;
      top: 0;
      display: flex;
      flex-direction: column;
      vertical-align: middle;

      &__btn {
        height: auto;
        margin: 0 0;
        padding: .5em 0;
        top: 0;
        right: 0;
        display: flex;
        justify-content: right;
        background-color: transparent;
        cursor: pointer;
        border: none;

        svg {
          height: 1em;
          width: 1.25em;
          margin: auto 0;
          left: 0;
          stroke: $txt_light;
        }
      }

      &__dropdown {
        height: max-content;
        width: calc(33vw - 1em);
        margin: .25em .5em;
        top: 0;
        right: 0;
        position: relative;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        text-align: right;
        overflow: hidden;

        a {
          margin: 1.5em 0;
          display: block;
        }
      }
      &__dropdown.show {
        display: block;
      }
      &__dropdown.hidden {
        display: none;
      }
    }
  }
}


@media (min-width: 425px) {
  .nav-cntr{
    .nav-inner-cntr {
      &__links {
        display: none;
        width: 15rem;
        margin: auto .25em auto 0;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
      .burger-menu {
        display: none;
      }
    }
  }
}


@media (min-width: 600px) {
  .nav-cntr{
    width: 100%;

    .nav-inner-cntr {
      width: 90%;
      padding: 0;

      &__logo {
        margin: auto 0;
      }

      &__links {
        width: 15rem;
        margin: auto 0;
      }
    }
  }
}


@media (min-width: 992px) {
  .nav-cntr {
    width: 100%;

    .nav-inner-cntr {
      width: 90%;
      max-width: 1200px;

      &__logo {
        margin: auto 0;
      }
    }
  }
}


// Scroll - show/hide nav
#nav {
  &.active {
    top: 0px;
    transition: top ease-out .4s;
  }

  &.active.false {
    top: -3em;
    transition: top ease-out .5s;
  }
}

// Dropdown backdrop
.backdrop {
  height: 100vh;
  width: 40vw;
  top: 0;
  right: 0em;
  margin: 0 0 0 auto;
  position: relative;
  z-index: 0;
  background: $black_transparent;
  backdrop-filter: blur(2px);
  box-shadow: 0 0 .5em 1px rgba(0, 0, 0, .2);
  overflow: hidden;

  &.show {
    display: block;
  }
  &.hidden {
    display: none;
  }
}
