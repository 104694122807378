// // Imports
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@300;400;500;600;700&family=Montserrat:wght@300;400;500;600;700&display=swap');

/* ---------- TYPOGRAPHY ---------- */
h2 {
    font-size: $fs_xxl;
    text-transform: uppercase;
    margin: .625rem 0;
}

h3 {
    font-size: $fs_l;
    margin-bottom: .625rem;
}

h4 {
    font-size: $fs_ml;
    text-transform: uppercase;
    margin-bottom: .625rem;
}

h5 {
    font-size: $fs_m;
    color: $txt_light;
    font-weight: 600;
    text-transform: uppercase;
    margin-bottom: .2rem;
    margin-top: 1rem;
}

p, span {
    font-size: $fs_s;
    margin-bottom: .625rem;
    word-spacing: 1px;
    letter-spacing: 0.25px;
}

